import React, { Component } from 'react'

export default class Project1 extends Component {
    render() {
        return (
            <section id="apartly">
          <h2>Apartly</h2>
          {/* <div>This project was developed as part of my bachelor's thesis in cooperation with the Robert Bosch start-up spexor. </div> */}
          </section>
        )
    }
}

